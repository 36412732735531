import { CalendarObjectSchema } from 'shared/types/common'

const departments = [
  {
    name: 'Продажи',
    items: [
      {
        guid: '4654',
        title: 'Операция',
        sum: '100 000',
      },
      {
        guid: '546',
        title: 'Операция',
        sum: '5 000 000',
      },
    ],
  },
  {
    name: 'Закупки',
    items: [
      {
        guid: '4654wed',
        title: 'Оплата поставщику',
        sum: '25 000',
      },
    ],
  },
  {
    name: 'Бухгалтерия',
    items: [
      {
        guid: '546wed',
        title: 'Налоги',
        sum: '25 000',
      },
    ],
  },
  {
    name: 'Маркетинг',
    items: [
      {
        guid: '546wedds',
        title: 'Яндекс Директ',
        sum: '250 000',
      },
    ],
  },
  {
    name: 'Логистика',
    items: [
      {
        guid: '4654wesdfd',
        title: 'Топливо',
        sum: '25 000',
      },
    ],
  },
  {
    name: 'Прочие',
    items: [
      {
        guid: '546sdfwed',
        title: 'Хоз нужды',
        sum: '25 000',
      },
    ],
  },
]

export const departmentsDateList: CalendarObjectSchema = {
  '2024-01-11': departments,
  '2024-01-12': [departments[0]],
  '2024-01-13': [departments[1]],
}
