import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router'
import { BlackButton } from 'shared/ui/BlackButton/BlackButton'

export const LogoutPage = () => {
  const navigate = useNavigate()

  const onCancel = () => navigate(-1)

  const onExit = () => {}

  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <Grid container rowSpacing={8} justifyContent="center">
          <Grid item width="85%">
            <Typography
              variant="h3"
              align="center"
              sx={{ fontSize: 32, fontWeight: 'medium' }}
            >
              Вы точно хотите выйти из приложения?
            </Typography>
          </Grid>
          <Grid />
          <Grid container item columnSpacing={5} justifyContent="center">
            <Grid item>
              <Button
                size="large"
                variant="contained"
                sx={{
                  fontSize: 32,
                  fontWeight: 'normal',
                  textTransform: 'capitalize',
                }}
                onClick={onCancel}
              >
                Отмена
              </Button>
            </Grid>
            <Grid item>
              <BlackButton
                size="large"
                variant="contained"
                sx={{
                  fontSize: 32,
                  fontWeight: 'normal',
                  textTransform: 'capitalize',
                }}
                onClick={onExit}
              >
                Выйти
              </BlackButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
