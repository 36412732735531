import { AccountsPage } from 'pages/AccountsPage'
import { ExpensesPage } from 'pages/ExpensesPage'
import { LoginPage } from 'pages/LoginPage'
import { LogoutPage } from 'pages/LogoutPage'
import { MainPage } from 'pages/MainPage'
import { NotFoundPage } from 'pages/NotFoundPage'
import { RouteProps } from 'react-router-dom'

export enum AppRoutes {
  MAIN = 'main',
  ACCOUNTS = 'accounts',
  EXPENSES = 'expenses',
  LOGIN = 'login',
  LOGOUT = 'logout',
  NOT_FOUND = 'not_found',
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: `/`,
  [AppRoutes.ACCOUNTS]: `/${AppRoutes.ACCOUNTS}`,
  [AppRoutes.EXPENSES]: `/${AppRoutes.EXPENSES}`,
  [AppRoutes.LOGOUT]: `/${AppRoutes.LOGOUT}`,
  [AppRoutes.LOGIN]: `/${AppRoutes.LOGIN}`,
  [AppRoutes.NOT_FOUND]: '*',
}

export const routeConfig: Record<AppRoutes, RouteProps> = {
  [AppRoutes.MAIN]: {
    path: RoutePath.main,
    element: <MainPage />,
  },
  [AppRoutes.ACCOUNTS]: {
    path: RoutePath.accounts,
    element: <AccountsPage />,
  },
  [AppRoutes.EXPENSES]: {
    path: RoutePath.expenses,
    element: <ExpensesPage />,
  },
  [AppRoutes.LOGIN]: {
    path: RoutePath.login,
    element: <LoginPage />,
  },
  [AppRoutes.LOGOUT]: {
    path: RoutePath.logout,
    element: <LogoutPage />,
  },
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath.not_found,
    element: <NotFoundPage />,
  },
}
