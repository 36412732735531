import { Box, ThemeProvider } from '@mui/material'
import { Suspense, useEffect, useState } from 'react'
import { Navbar } from 'widgets/Navbar'
import { AppRouter } from './providers/router'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { GlobalStyle } from './styles/GlobalStyle'
import { newTheme } from './theme/theme'
import '../firebase'
import { onMessageListener, requestForToken } from '../firebase'

function App() {
  const [show, setShow] = useState(false)
  const [notification, setNotification] = useState({ title: '', body: '' })

  requestForToken()
 
  onMessageListener()
    .then((payload) => {
      setShow(true)
      if (payload) {
        setNotification({
          title: payload?.notification?.title ?? '',
          body: payload?.notification?.body ?? '',
        })
      }
      console.log(payload)
    })
    .catch((err) => console.log('failed: ', err))

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={newTheme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Suspense fallback="">
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="space-between"
            >
              <AppRouter />
              <Navbar />
            </Box>
          </Suspense>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  )
}

export default App
