import { Box, Grid, Typography, Switch, BoxProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { PaletteVariant } from 'shared/types/common'

type OperationListProps<Data> = {
  header: string
  data: Array<Data>
  variant: PaletteVariant
  getId: (data: Data) => string | number
  getTitle: (data: Data) => string
  getDescription: (data: Data) => string
  top?: BoxProps['top'],
  getType?: (data: Data) => string
  onChange?: (data: Data) => void
}

export function OperationList<Data>({
  header,
  data,
  variant,
  top,
  getId,
  getTitle,
  getDescription,
  getType,
  onChange,
}: OperationListProps<Data>) {
  const { palette } = useTheme()
  return (
    <Box bgcolor="white">
      <Box
        px={1}
        py={1}
        bgcolor={palette[variant].light}
        borderRadius={3}
        position="sticky"
        top={top}
        zIndex={10}
      >
        <Typography
          fontSize={14}
          fontWeight="bold"
          color={palette[variant].main}
        >
          {header}
        </Typography>
      </Box>
      {data.map((item) => {
        const id = getId(item)
        const title = getTitle(item)
        const description = getDescription(item)
        const type = getType ? getType(item) : ''
        return (
          <Grid container px={'10px'} key={id} py={.5}>
            <Grid item xs={10}>
              <Grid item xs={12}>
                <Typography variant="h6" fontWeight="500">
                  {title}
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={6}>
                  <Typography>{description}</Typography>
                </Grid>
                <Grid item xs={6} color={palette.secondary.main} fontSize={16}>
                  <Typography>{type}</Typography>
                </Grid>
              </Grid>
            </Grid>
            {onChange && (
              <Grid item container xs={2} alignItems="center">
                <Switch />
              </Grid>
            )}
          </Grid>
        )
      })}
    </Box>
  )
}
