import { Box, BottomNavigation, BottomNavigationAction } from '@mui/material'
import { useNavigate, useLocation, matchPath } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'
import DescriptionIcon from '@mui/icons-material/Description'
import PaymentsIcon from '@mui/icons-material/Payments'
import LockIcon from '@mui/icons-material/Lock'
import { useEffect, useState } from 'react'
import { RoutePath } from 'shared/config/routeConfig/routeConfig'
import { grey } from '@mui/material/colors'

const navbarItems = [
  {
    value: 1,
    label: 'Главная',
    icon: <HomeIcon sx={{ fontSize: 35 }} />,
    url: RoutePath.main,
  },
  {
    value: 2,
    label: 'Счета',
    icon: <DescriptionIcon sx={{ fontSize: 35 }} />,
    url: RoutePath.accounts,
  },
  {
    value: 3,
    label: 'Расходы',
    icon: <PaymentsIcon sx={{ fontSize: 35 }} />,
    url: RoutePath.expenses,
  },
  {
    value: 4,
    label: 'Выход',
    icon: <LockIcon sx={{ fontSize: 35 }} />,
    url: RoutePath.logout,
  },
]

export const Navbar = () => {
  const [value, setValue] = useState(0)
  const [visible, setVisible] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const targetItem = navbarItems.find((item) =>
      matchPath(location.pathname, item.url)
    )
    if (targetItem) {
      setValue(targetItem.value)
      if (targetItem.value === 4) setVisible(false)
      else setVisible(true)
    } else {
      setValue(0)
    }
  }, [location])

  if (!visible) return null

  return (
    <Box
      borderTop={`1px solid ${grey[300]}`}
      position="fixed"
      bottom={0}
      width="100%"
      zIndex={999}
    >
      <BottomNavigation
        sx={{ height: 60 }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue)
        }}
      >
        {navbarItems.map(({ label, url, ...otherProps }) => (
          <BottomNavigationAction
            key={label}
            label={<Box sx={{ fontSize: 14 }}>{label}</Box>}
            onClick={() => navigate(url)}
            {...otherProps}
          />
        ))}
      </BottomNavigation>
    </Box>
  )
}
