import { CalendarObjectSchema } from "shared/types/common"

const operations = [
  {
    name: 'Не подтверждено',
    items: [
      {
        guid: '4654',
        title: 'ИП Колесников',
        sum: '100 000',
        type: 'С1',
      },
      {
        guid: '546',
        title: 'Тимашевский инкубатор',
        sum: '5 000 000',
        type: 'P1',
      },
    ],
  },
  {
    name: 'Разрешено',
    items: [
      {
        guid: '4654wed',
        title: 'УНИВЕРСАЛТОРГ',
        sum: '25 000',
        type: 'С1',
      },
      {
        guid: '546wed',
        title: 'Мясофактура',
        sum: '25 000',
        type: 'P1',
      },
      {
        guid: '546wedds',
        title: 'Ип Казанцева',
        sum: '250 000',
        type: 'P1',
      },
      {
        guid: '4654wesdfd',
        title: 'УНИВЕРСАЛТОРГ',
        sum: '25 000',
        type: 'С1',
      },
      {
        guid: '546sdfwed',
        title: 'Мясофактура',
        sum: '25 000',
        type: 'P1',
      },
      {
        guid: '546sdsdfsdffwedds',
        title: 'Ип Казанцева',
        sum: '250 000',
        type: 'P1',
      },
      {
        guid: '4654sdfwed',
        title: 'УНИВЕРСАЛТОРГ',
        sum: '25 000',
        type: 'С1',
      },
      {
        guid: '546sdfswed',
        title: 'Мясофактура',
        sum: '25 000',
        type: 'P1',
      },
      {
        guid: '546sdfwedds',
        title: 'Ип Казанцева',
        sum: '250 000',
        type: 'P1',
      },
      {
        guid: '465sdf4wesdfd',
        title: 'УНИВЕРСАЛТОРГ',
        sum: '25 000',
        type: 'С1',
      },
      {
        guid: '546ssdfdfwed',
        title: 'Мясофактура',
        sum: '25 000',
        type: 'P1',
      },
      {
        guid: '546sdfsdfwedds',
        title: 'Ип Казанцева',
        sum: '250 000',
        type: 'P1',
      },
    ],
  },
]

export const operationsDateList: CalendarObjectSchema = {
  '2024-01-11': operations,
  '2024-01-12': [operations[0]],
  '2024-01-13': [operations[1]],
}
