import { useState, useRef, useEffect, useMemo } from 'react'
import { Stack, Box, Typography } from '@mui/material'
import moment, { Moment } from 'moment'
import { useWindowSize } from 'shared/hooks/useWindowSize'
import { OperationList } from 'entities/Operation'
import { Calendar } from 'widgets/Calendar'
import { ToggleOperationFilter } from 'shared/ui/ToggleOperationFilter/ToggleOperationFilter'
import { getVariantByOperationName } from 'shared/lib/getOperationVariantByName'
import { grey } from '@mui/material/colors'
import { useTheme } from '@mui/material/styles'
import { OperationSchema } from 'shared/types/common'
import { departmentsDateList } from 'shared/mocks/departments.mock'

export const ExpensesPage = () => {
  const [operationFilterValue, setOperationFilterValue] = useState<
    Array<string>
  >([])
  const [preFilteredOperations, setPreFilteredOperations] = useState<
    Array<OperationSchema>
  >([])
  const [selectedDay, setSelectedDay] = useState(moment())
  const { windowHeight } = useWindowSize()
  const { palette } = useTheme()
  const headerRef = useRef<HTMLDivElement>(null)

  const departments = useMemo(() => {
    return departmentsDateList[selectedDay.format('YYYY-MM-DD')] || []
  }, [selectedDay])

  useEffect(() => {
    if (operationFilterValue.length) {
      const filteredData = departments.filter(
        (operation) => operationFilterValue.indexOf(operation.name) !== -1
      )
      setPreFilteredOperations(filteredData)
    } else setPreFilteredOperations(departments)
  }, [selectedDay, operationFilterValue, departments])

  const operationFilterOnChange = (newValue: string) =>
    setOperationFilterValue((prevValue) => {
      if (prevValue.indexOf(newValue) === -1) return [...prevValue, newValue]
      return prevValue.filter((value) => value !== newValue)
    })

  const getDayDotsVariants = (day: Moment) => {
    const dayInfo = departmentsDateList[day.format('YYYY-MM-DD')]
    return (
      dayInfo &&
      dayInfo
        .filter(
          ({ name }) =>
            !operationFilterValue.length ||
            operationFilterValue.find((currentValue) => currentValue === name)
        )
        .map(({ name }) => getVariantByOperationName(name))
    )
  }

  return (
    <Stack pt="155px" pb="60px">
      <Stack
        spacing={1}
        position="fixed"
        top={0}
        bgcolor="white"
        zIndex={100}
        pb={1}
        ref={headerRef}
        borderBottom={`1px solid ${grey[300]}`}
      >
        <Typography variant="h4" fontWeight="bold" px={2} pt={2}>
          Расходы отделов
        </Typography>
        <ToggleOperationFilter
          options={[
            'Продажи',
            'Закупки',
            'Бухгалтерия',
            'Логистика',
            'Прочие',
            'Маркетинг',
          ]}
          getOptionValue={(option) => option}
          getLabel={(option) => option}
          isSelected={(option) => operationFilterValue.indexOf(option) !== -1}
          onChange={operationFilterOnChange}
        />
      </Stack>
      <Stack spacing={2}>
        <Box pt={1}>
          <Calendar
            day={selectedDay}
            onChange={setSelectedDay}
            getDayDotsVariants={getDayDotsVariants}
          />
        </Box>
        <Box px={2}>
          <Box position="sticky" top={0} bgcolor="white" zIndex={20} pb={1}>
            <Typography variant="h5" color={palette.primary.main}>
              {selectedDay.isSame(moment(), 'day')
                ? 'Сегодня'
                : selectedDay.format('D MMMM')}
            </Typography>
          </Box>
          {preFilteredOperations.map(({ name, items }) => (
            <OperationList
              key={name}
              header={name}
              data={items}
              getId={(data) => data.guid}
              getTitle={(data) => data.title}
              getDescription={(data) => `${data.sum} руб`}
              variant={getVariantByOperationName(name)}
              top={headerRef.current?.offsetHeight}
            />
          ))}
        </Box>
      </Stack>
    </Stack>
  )
}
