import { initializeApp } from 'firebase/app'
import {
  getMessaging,
  getToken,
  MessagePayload,
  onMessage,
} from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyCnyNoU6vp7yFahkLxxGn78zQhAHDyXmeI',
  authDomain: 'cash-management-system-a7837.firebaseapp.com',
  projectId: 'cash-management-system-a7837',
  storageBucket: 'cash-management-system-a7837.appspot.com',
  messagingSenderId: '867587086796',
  appId: '1:867587086796:web:b936a4578be6500478e569',
  measurementId: 'G-Y1Z849MWVY',
}

console.log(process.env)

const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        'BHsyDEKk-0rACLjcadC47oMukLEbGocqvmSDlMWSvoc4rvZ3LJJVcrSCUjJomEhpuHZjhokf_1PerIy3xlxZmX0',
    })
    if (currentToken) {
      console.log('current token for client: ', currentToken)
      
    } else {
      console.log(
        'No registration token available. Request permission to generate one.'
      )
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err)
  }
}

export const onMessageListener = (): Promise<MessagePayload> =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })
