

export const LoginPage = () => {
    return (
        <div>
            авторизация
        </div>
    );
};

