

export const AccountsPage = () => {
    return (
        <div>
            счета
        </div>
    );
};

