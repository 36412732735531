import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Box, Stack } from '@mui/material'
import { blue, grey } from '@mui/material/colors'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { DateCalendar,PickersDay, PickersDayProps } from '@mui/x-date-pickers'
import moment, { Moment } from 'moment'
import { PaletteVariant } from 'shared/types/common'

interface CustomPickerDayProps extends PickersDayProps<Moment> {
  isSelected: boolean
  isHovered: boolean
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<CustomPickerDayProps>(
  ({ theme, isSelected, isHovered, day, today, outsideCurrentMonth }) => {
    return {
      borderRadius: '50%',
      fontSize: 16,
      ...(isSelected && {
        backgroundColor: 'rgba(79,172,254, 0.41)',
        '&:hover, &:focus': {
          backgroundColor: 'rgba(79,172,254, 0.41)',
        },
      }),
      ...(isHovered && {
        backgroundColor: blue[50],
        '&:hover, &:focus': {
          backgroundColor: blue[50],
        },
      }),
      ...(!outsideCurrentMonth && { backgroundColor: '#F3F3F3' }),
    }
  }
) as React.ComponentType<CustomPickerDayProps>

type DayProps = PickersDayProps<Moment> & {
  selectedDay?: Moment | null
  hoveredDay?: Moment | null
  dotsVariants?: Array<PaletteVariant>
}

const Day = (props: DayProps) => {
  const { day, selectedDay, hoveredDay, dotsVariants, ...other } = props
  const { palette } = useTheme()
  return (
    <Box width={45} height={45} position="relative">
      
      <CustomPickersDay
        {...other}
        day={day}
        sx={{ p: 2.6, m: 0.2 }}
        disableMargin
        selected={false}
        isSelected={day.isSame(selectedDay, 'day')}
        isHovered={day.isSame(hoveredDay, 'day')}
      />
      {!other.outsideCurrentMonth && dotsVariants && (
        <Stack
          direction="row"
          width="100%"
          justifyContent="center"
          position="absolute"
          bottom={10}
          left={0}
        >
          {dotsVariants.map((variant) => (
            <FiberManualRecordIcon
              key={variant}
              sx={{
                fontSize: 5,
                color: palette[variant].main,
              }}
            />
          ))}
        </Stack>
      )}
    </Box>
  )
}

interface CalendarProps {
  day: Moment
  onChange: (day: Moment) => void
  getDayDotsVariants?: (day: Moment) => Array<PaletteVariant>
}

export const Calendar = ({
  day,
  onChange,
  getDayDotsVariants,
}: CalendarProps) => {
  const [hoveredDay, setHoveredDay] = useState<Moment | null>(null)
  const [currentMonth, setCurrentMonth] = useState(moment().startOf('month'))

  const monthOnChange = (newMonth) => setCurrentMonth(newMonth)

  console.log('!!!', currentMonth)
  return (
    <>
      <button onClick={() => setCurrentMonth(moment('2024-12-05'))}>fff</button>
      <DateCalendar
        views={['day']}
        value={day}
        slots={{
          day: Day,
        }}
        slotProps={{
          day: (ownerState) => {
            return {
              selectedDay: day,
              hoveredDay,
              dotsVariants:
                getDayDotsVariants && getDayDotsVariants(ownerState.day),
              onPointerEnter: () => setHoveredDay(ownerState.day),
              onPointerLeave: () => setHoveredDay(null),
            } as any
          },
          //  calendarHeader: (ownerState) => ({ currentMonth }),
        }}
        onChange={(date, fff, ff) => {
          // console.log(fff, ff)
          onChange(date)
        }}
        onMonthChange={monthOnChange}
        showDaysOutsideCurrentMonth
        sx={{ boxShadow: `0px 0px 5px ${grey[400]}`, borderRadius: 3 }}
      />
    </>
  )
}
